// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_ButtonBgColor__BjIxO {
  background-color: var(--primary-color);
  white-space: nowrap;
}

.Button_ButtonBoxShadow__Kiyh0 {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
    0px 6.96169376373291px 17.40423583984375px 0px rgba(224, 89, 42, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE;yEACuE;AACzE","sourcesContent":[".ButtonBgColor {\n  background-color: var(--primary-color);\n  white-space: nowrap;\n}\n\n.ButtonBoxShadow {\n  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),\n    0px 6.96169376373291px 17.40423583984375px 0px rgba(224, 89, 42, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ButtonBgColor": `Button_ButtonBgColor__BjIxO`,
	"ButtonBoxShadow": `Button_ButtonBoxShadow__Kiyh0`
};
export default ___CSS_LOADER_EXPORT___;
