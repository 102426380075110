// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessagCard_title__bH8DM {
    color: var(--primary-color);
    font-family: Open Sans;
    font-size: 32px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}

.MessagCard_subtitle__I9BSZ {
    color: #53605F;
    font-family: var(--font-open-sans);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.MessagCard_normalTitle__\\+oLBT {

    color: #EA4B22;
    font-family: Open Sans;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/MessageCard/MessagCard.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kCAAkC;IAClC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;;IAEI,cAAc;IACd,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":[".title {\n    color: var(--primary-color);\n    font-family: Open Sans;\n    font-size: 32px;\n    font-style: italic;\n    font-weight: 700;\n    line-height: normal;\n}\n\n.subtitle {\n    color: #53605F;\n    font-family: var(--font-open-sans);\n    font-size: 22px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n}\n\n.normalTitle {\n\n    color: #EA4B22;\n    font-family: Open Sans;\n    font-size: 19px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 35px;\n    text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `MessagCard_title__bH8DM`,
	"subtitle": `MessagCard_subtitle__I9BSZ`,
	"normalTitle": `MessagCard_normalTitle__+oLBT`
};
export default ___CSS_LOADER_EXPORT___;
