import React from "react";

const MessageCardLine = ({ lineWidth, background }) => {
  return (
    <>
      <div
        style={{
          height: "1px",
          width: lineWidth,
          background: background ? background : "rgba(109, 110, 113, 0.13)",
        }}
      ></div>
      &nbsp;
    </>
  );
};

export default MessageCardLine;
