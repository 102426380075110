// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Assessment_Card__Ju0SN{
    border-radius: 4px;

    box-shadow: 0px 16px 30px 0px rgba(76, 90, 90, 0.10);
}
.Assessment_HeaderTitle__FGFqx {
    color: #EA4B22;
    font-family: Open Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Assessment_HeaderLine__jmdGZ{
 height: 1px;
 background: rgba(109, 110, 113, 0.13);
}

.Assessment_QuestionFontStyle__RGwj0{

    color: #4C5A5A;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.Assessment_OptionFontStyle__3fqIF{

    color: #4C5A5A;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.8;
}


input[type="radio"] {
    appearance: none;
    width: 24px;
    height: 24px;

    border-radius: 4px;
border: 1px solid #E7E0D3;
background: rgba(255, 255, 255, 0.60);
box-sizing: border-box;
  }
  


  
  input[type=radio]:checked {

 background: #EA4B22; 
    border-radius: 4px;

    outline: 2px solid #EA4B22;
    outline-offset: 2px; 
    background-size: 9px 9px; 

}

`, "",{"version":3,"sources":["webpack://./src/components/Assessment/Assessment.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,oDAAoD;AACxD;AACA;IACI,cAAc;IACd,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;CACC,WAAW;CACX,qCAAqC;AACtC;;AAEA;;IAEI,cAAc;IACd,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;;IAEI,cAAc;IACd,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;AAChB;;;AAGA;IAGI,gBAAgB;IAChB,WAAW;IACX,YAAY;;IAEZ,kBAAkB;AACtB,yBAAyB;AACzB,qCAAqC;AAGrC,sBAAsB;EACpB;;;;;EAKA;;CAED,mBAAmB;IAChB,kBAAkB;;IAElB,0BAA0B;IAC1B,mBAAmB;IACnB,wBAAwB;;AAE5B","sourcesContent":[".Card{\n    border-radius: 4px;\n\n    box-shadow: 0px 16px 30px 0px rgba(76, 90, 90, 0.10);\n}\n.HeaderTitle {\n    color: #EA4B22;\n    font-family: Open Sans;\n    font-size: 22px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n}\n\n.HeaderLine{\n height: 1px;\n background: rgba(109, 110, 113, 0.13);\n}\n\n.QuestionFontStyle{\n\n    color: #4C5A5A;\n    font-family: Open Sans;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n}\n\n.OptionFontStyle{\n\n    color: #4C5A5A;\n    font-family: Open Sans;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n    opacity: 0.8;\n}\n\n\ninput[type=\"radio\"] {\n    -webkit-appearance: none; \n    -moz-appearance: none;\n    appearance: none;\n    width: 24px;\n    height: 24px;\n\n    border-radius: 4px;\nborder: 1px solid #E7E0D3;\nbackground: rgba(255, 255, 255, 0.60);\n-webkit-box-sizing: border-box;\n-moz-box-sizing: border-box;\nbox-sizing: border-box;\n  }\n  \n\n\n  \n  input[type=radio]:checked {\n\n background: #EA4B22; \n    border-radius: 4px;\n\n    outline: 2px solid #EA4B22;\n    outline-offset: 2px; \n    background-size: 9px 9px; \n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Card": `Assessment_Card__Ju0SN`,
	"HeaderTitle": `Assessment_HeaderTitle__FGFqx`,
	"HeaderLine": `Assessment_HeaderLine__jmdGZ`,
	"QuestionFontStyle": `Assessment_QuestionFontStyle__RGwj0`,
	"OptionFontStyle": `Assessment_OptionFontStyle__3fqIF`
};
export default ___CSS_LOADER_EXPORT___;
