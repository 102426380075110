import { MoreVert } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  TextField,
} from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import Button from '../Button/Button'
import Spinner from '../Spinner/Spinner'
import { Update_Learner } from '../../api/graphql'
import { useMutation } from '@apollo/client'

export default function ActionMenu({ item, getList }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [editOpen, setEditOpen] = useState(false)
  const open = Boolean(anchorEl)

  const FormSchema = Yup.object().shape({
    firstName: Yup.string().trim().required('First Name is required'),
    lastName: Yup.string().trim().required('Last Name is required'),
    email: Yup.string().email().trim().required('Email is required'),
  })

  const [UPDATE_LEARNER, { loading }] = useMutation(Update_Learner)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }
  function handleClose() {
    setAnchorEl(null)
  }
  function handleEdit() {
    setEditOpen(true)
    setAnchorEl(null)
  }
  function handleDelete() {
    // Delete Logic Here
    setAnchorEl(null)
  }
  function handleResend() {
    // Resend Logic Here
    setAnchorEl(null)
  }
  function handleCopyInvite() {
    // Copy Invite Logic Here
    setAnchorEl(null)
  }
  async function handleEditForm(values) {
    const { data } = await UPDATE_LEARNER({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email?.trim()?.toLowerCase(),
        trainingId: item.training_id,
        waivePrecheck: values.waivePrecheck || false,
      },
    })
    if (!data.inviteLearner.success) {
      handleClose()
      getList()
      // toast.error(data?.inviteLearner?.error || data?.inviteLearner?.message)
      return
    }
    // toast.success('Invitation send successfully')

    handleClose()
    getList()
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        <MenuItem onClick={handleResend}>Resend Invite</MenuItem>
        <MenuItem onClick={handleCopyInvite}>Copy Invite Link</MenuItem>
      </Menu>
      <Modal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableBackdropClick={true}
      >
        <Box
          sx={{
            position: 'absolute',
            maxwidth: 550,
            bgcolor: 'background.paper',
            border: '0.5px solid #000',
            borderRadius: '10px',
            p: 4,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <h5>Edit a Learner</h5>
          <p>
            Your new project has been created. Invite colleagues to collaborate
            on this project
          </p>
          <Formik
            enableReinitialize={true}
            initialValues={{
              firstName: item.first_name,
              lastName: item.last_name,
              email: item.email,
              waivePrecheck: item.waive_precheck,
            }}
            validationSchema={FormSchema}
            onSubmit={handleEditForm}
          >
            {({
              errors,
              values,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="row">
                    <div className="col-md-6 mb-3 flex-column d-flex">
                      <TextField
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        value={values.firstName}
                        label="First Name *"
                        error={errors.firstName}
                        touched={touched.firstName}
                        handleBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 mb-3 flex-column d-flex">
                      <TextField
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        value={values.lastName}
                        label="Last Name *"
                        error={errors.lastName}
                        touched={touched.lastName}
                        handleBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-3 flex-column d-flex">
                      <TextField
                        name="email"
                        placeholder="Email"
                        value={values.email?.trim()?.toLowerCase()}
                        label="Email address *"
                        error={errors.email}
                        touched={touched.email}
                        handleBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-3 flex-column d-flex">
                      <div className="row align-items-center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              label="Waive Pre-check"
                              inputProps={{
                                'aria-label': 'controlled',
                              }}
                              name="waivePrecheck"
                              id="waivePrecheckId"
                              onClick={(e) => {
                                setFieldValue(
                                  'waivePrecheck',
                                  !values.waivePrecheck
                                )
                              }}
                              checked={values.waivePrecheck}
                            />
                          }
                          label="Waive Pre-Check"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3 flex-column d-flex">
                      <Button
                        className="button-base-instance-grey"
                        variant="contained"
                        onClick={() => setEditOpen(false)}
                      >
                        Back
                      </Button>
                    </div>
                    <div className="col-md-6 mb-3 flex-column d-flex">
                      <Button type="submit">
                        {loading ? (
                          <Spinner
                            size="20px"
                            sx={{
                              '.MuiCircularProgress-root': {
                                color: 'white',
                              },
                            }}
                            thickness={5}
                          />
                        ) : (
                          <>Edit Invitation</>
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              )
            }}
          </Formik>
        </Box>
      </Modal>
    </>
  )
}
