// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserBadge_userBadge__pMdG4{

    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/UserBadge/UserBadge.module.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;AACnB","sourcesContent":[".userBadge{\n\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userBadge": `UserBadge_userBadge__pMdG4`
};
export default ___CSS_LOADER_EXPORT___;
