// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-3 {
  color: #53605f;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
}

.compImage {
  height: 33px;
  width: 33px;
}
.group:hover {
  text-decoration: none !important;
}
.list-group-item.active {
  background-color: var(--primary-color) !important;
}
.navstyle {
  list-style-type: none;
  padding: 0rem !important;
  flex-direction: column;
}
.nav-item {
  padding: 5px 0px;
  white-space: nowrap;
}
.nav-link {
  font-size: 18px;
  text-align: left !important;
  padding: 10px;
  text-decoration: none;
}
.nav-link:hover,
.activeNav:hover {
  text-decoration: none !important;
  color: #fff !important;
}
.activeNav {
  font-size: 18px !important;
  text-align: left !important;
  padding: 10px !important;
  text-decoration: none;
  text-decoration: none !important;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff !important;
  border-radius: 10px;
  padding-right: 100px !important;
}
@media screen and (max-width: 990px) {
  .nav-link {
    padding: 0px !important;
  }
  .activeNav {
    padding: 2px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbarmodule.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,gCAAgC;AAClC;AACA;EACE,iDAAiD;AACnD;AACA;EACE,qBAAqB;EACrB,wBAAwB;EACxB,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,2BAA2B;EAC3B,aAAa;EACb,qBAAqB;AACvB;AACA;;EAEE,gCAAgC;EAChC,sBAAsB;AACxB;AACA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,wBAAwB;EACxB,qBAAqB;EACrB,gCAAgC;EAChC,sCAAsC;EACtC,kCAAkC;EAClC,sBAAsB;EACtB,mBAAmB;EACnB,+BAA+B;AACjC;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".text-3 {\n  color: #53605f;\n  font-size: 20px;\n  font-weight: 700;\n  letter-spacing: 0;\n  line-height: 28px;\n}\n\n.compImage {\n  height: 33px;\n  width: 33px;\n}\n.group:hover {\n  text-decoration: none !important;\n}\n.list-group-item.active {\n  background-color: var(--primary-color) !important;\n}\n.navstyle {\n  list-style-type: none;\n  padding: 0rem !important;\n  flex-direction: column;\n}\n.nav-item {\n  padding: 5px 0px;\n  white-space: nowrap;\n}\n.nav-link {\n  font-size: 18px;\n  text-align: left !important;\n  padding: 10px;\n  text-decoration: none;\n}\n.nav-link:hover,\n.activeNav:hover {\n  text-decoration: none !important;\n  color: #fff !important;\n}\n.activeNav {\n  font-size: 18px !important;\n  text-align: left !important;\n  padding: 10px !important;\n  text-decoration: none;\n  text-decoration: none !important;\n  background-color: var(--primary-color);\n  border-color: var(--primary-color);\n  color: #fff !important;\n  border-radius: 10px;\n  padding-right: 100px !important;\n}\n@media screen and (max-width: 990px) {\n  .nav-link {\n    padding: 0px !important;\n  }\n  .activeNav {\n    padding: 2px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
