import React, { useState, useEffect } from 'react'
import Papa from 'papaparse'
import Header from '../../components/Header/Header'
import NavBar from '../../components/Navbar/Navbar'
import Spinner from '../../components/Spinner/Spinner'
import Table from '../../components/Table/Table'
import Button from '../../components/Button/Button'
import { useLocation, useNavigate } from 'react-router-dom'

export default function AdminReportsSummary() {
  const location = useLocation()
  const navigate = useNavigate()
  const userType = localStorage.getItem('userType')

  const [data, setData] = useState([])
  const [csvData, setCsvData] = useState([])

  useEffect(() => {
    // FIXME: Temporary check for admin
    if (!location.state || !location.state.id) {
      navigate(userType === 'admin' ? '/admin/reports/summary' : '/')
      // return
    }
  }, [])

  function calculatePercentages(data) {}

  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      // Read the file
      const reader = new FileReader()
      reader.onload = (e) => {
        // Parse CSV data
        Papa.parse(e.target.result, {
          header: true,
          complete: (result) => {
            // take the result and map all missing values from the cell above
            // if the above cell is empty, keep going up till it's not empty
            const filteredData = result?.data?.map((row, index) => {
              const newRow = {}
              Object.keys(row).forEach((key) => {
                if (row[key] === '') {
                  let i = index - 1
                  while (i >= 0) {
                    if (result.data[i][key] !== '') {
                      newRow[key] = result.data[i][key]
                      break
                    }
                    i--
                  }
                } else {
                  newRow[key] = row[key]
                }
              })
              return newRow
            })
            setCsvData(filteredData)
            calculatePercentages(filteredData)
          },
        })
      }
      reader.readAsText(file)
    }
  }

  // Function to organize and calculate percentages
  const organizeData = () => {
    // Organize data based on departments and aspects
    // Calculate percentages for each aspect
    // Return organized data
  }

  return (
    <>
      <div className="row p-0 nav-area no-gutters">
        <div className="row no-gutters p-sm-0 p-lg-3">
          <Header />
          <div className="row p-0 no-gutters">
            <div className="col-2">
              <NavBar user={'admin'} />
            </div>
            <div className="col-10 admin-request">
              <div
                className="row mb-4"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div className="col-xl-2 row">
                  <h2 className="instructor-title">Reports</h2>
                </div>
                <div
                  className="col-xl-10"
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <input
                    type="file"
                    onChange={handleFileUpload}
                    accept=".csv"
                  />
                  <div
                    style={{
                      width: 'auto',
                    }}
                  >
                    <Button
                      onClick={() => setData(csvData)}
                      disabled={csvData?.length <= 0 || csvData === data}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
              <div className="row bg-white rounded no-gutters table-responsive">
                <Table
                  columns={
                    data.length > 0
                      ? Object.keys(data[0]).map((header, index) => ({
                          header: header,
                          path: header,
                        }))
                      : []
                  }
                  className={'insCertTable'}
                  data={data}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** BREAK HERE */}
    </>
  )
}
