// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.text-3 {
  color: #53605f;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
}

.compImage {
  height: 33px;
  width: 33px;
}
.group:hover {
  text-decoration: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":";;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,gCAAgC;AAClC","sourcesContent":["\n\n.text-3 {\n  color: #53605f;\n  font-size: 20px;\n  font-weight: 700;\n  letter-spacing: 0;\n  line-height: 28px;\n}\n\n.compImage {\n  height: 33px;\n  width: 33px;\n}\n.group:hover {\n  text-decoration: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
