// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-stepper .react-form-stepper-step::before {
    width: 10px;
    /* Adjust the width as needed */
    height: 10px;
    /* Adjust the height as needed */
}


.custom-stepper .custom-stepper .react-form-stepper-step::before {
    content: none;
    /* Remove the content (number) */
}

.StepButtonContent-0-2-5 {
    display: none ;
}



.StepButton-0-2-4{
z-index: 50;
}


.shadowOutlineCurrentQn#RFS-StepButton {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.654);
    outline:3px solid #FFF;

}`, "",{"version":3,"sources":["webpack://./src/components/Assessment/stepper.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,+BAA+B;IAC/B,YAAY;IACZ,gCAAgC;AACpC;;;AAGA;IACI,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,cAAc;AAClB;;;;AAIA;AACA,WAAW;AACX;;;AAGA;IACI,6CAA6C;IAC7C,sBAAsB;;AAE1B","sourcesContent":[".custom-stepper .react-form-stepper-step::before {\n    width: 10px;\n    /* Adjust the width as needed */\n    height: 10px;\n    /* Adjust the height as needed */\n}\n\n\n.custom-stepper .custom-stepper .react-form-stepper-step::before {\n    content: none;\n    /* Remove the content (number) */\n}\n\n.StepButtonContent-0-2-5 {\n    display: none ;\n}\n\n\n\n.StepButton-0-2-4{\nz-index: 50;\n}\n\n\n.shadowOutlineCurrentQn#RFS-StepButton {\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.654);\n    outline:3px solid #FFF;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
