// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminReports_insCertTable__5a1EE th {
  background-color: #f0f0f0;
  height: 40px;
  color: #6f767e;
  font-size: 13px;
  font-weight: 700;
  white-space: nowrap;
}
.AdminReports_insCertTable__5a1EE td {
  color: #53605f;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AdminReports/AdminReports.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".insCertTable th {\n  background-color: #f0f0f0;\n  height: 40px;\n  color: #6f767e;\n  font-size: 13px;\n  font-weight: 700;\n  white-space: nowrap;\n}\n.insCertTable td {\n  color: #53605f;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 24px;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"insCertTable": `AdminReports_insCertTable__5a1EE`
};
export default ___CSS_LOADER_EXPORT___;
