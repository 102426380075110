// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimeField_inputLabel__faeXu {
  color: #53605f;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 10px;
  padding-bottom: 5px;
}

/* .inputLabel::after {
	content: "*";
	color: red;
	padding-left: 1px;
  } */

.TimeField_inputWrapper__4jyWt {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--grey-2);
  padding: var(--padding-xs) var(--padding-3xs);
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
}

.TimeField_errorMessage__jVwkF {
  color: red;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/TimeField/TimeField.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;;;;KAIK;;AAEL;EACE,4BAA4B;EAC5B,8BAA8B;EAC9B,+BAA+B;EAC/B,6CAA6C;EAC7C,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".inputLabel {\n  color: #53605f;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  padding-top: 10px;\n  padding-bottom: 5px;\n}\n\n/* .inputLabel::after {\n\tcontent: \"*\";\n\tcolor: red;\n\tpadding-left: 1px;\n  } */\n\n.inputWrapper {\n  border-radius: var(--br-9xs);\n  background-color: var(--white);\n  border: 1px solid var(--grey-2);\n  padding: var(--padding-xs) var(--padding-3xs);\n  align-items: center;\n  justify-content: flex-start;\n  align-self: stretch;\n}\n\n.errorMessage {\n  color: red;\n  margin-top: 10px;\n  font-size: 13px;\n  font-weight: 600;\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": `TimeField_inputLabel__faeXu`,
	"inputWrapper": `TimeField_inputWrapper__4jyWt`,
	"errorMessage": `TimeField_errorMessage__jVwkF`
};
export default ___CSS_LOADER_EXPORT___;
