// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DropDownMenu_DropDownCard__BQyEa {
  border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0px 13px 32px 0px rgba(83, 96, 95, 0.12);
z-index:3 !important;

}

.DropDownMenu_DropDownItem__oa62b {

  width: 100%;
  cursor: pointer;

  /* background-color: #FFF; */

}

.DropDownMenu_DropDownItemBorder__uKUey{

  border-bottom: 1px solid rgba(83, 96, 95, 0.1) !important;
}


.DropDownMenu_DropDownItemText__UvHVz {
  color: #53605f;
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.14px;
}

.DropDownMenu_IconStyle__40Tc6 {
  width: 18px;
  height: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DropDownMenu/DropDownMenu.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,oDAAoD;AACtD,oBAAoB;;AAEpB;;AAEA;;EAEE,WAAW;EACX,eAAe;;EAEf,4BAA4B;;AAE9B;;AAEA;;EAEE,yDAAyD;AAC3D;;;AAGA;EACE,cAAc;EACd,kCAAkC;EAClC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;EAChC,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".DropDownCard {\n  border-radius: 4px;\n  border-radius: 4px;\n  box-shadow: 0px 13px 32px 0px rgba(83, 96, 95, 0.12);\nz-index:3 !important;\n\n}\n\n.DropDownItem {\n\n  width: 100%;\n  cursor: pointer;\n\n  /* background-color: #FFF; */\n\n}\n\n.DropDownItemBorder{\n\n  border-bottom: 1px solid rgba(83, 96, 95, 0.1) !important;\n}\n\n\n.DropDownItemText {\n  color: #53605f;\n  font-family: var(--font-open-sans);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px; /* 157.143% */\n  letter-spacing: -0.14px;\n}\n\n.IconStyle {\n  width: 18px;\n  height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DropDownCard": `DropDownMenu_DropDownCard__BQyEa`,
	"DropDownItem": `DropDownMenu_DropDownItem__oa62b`,
	"DropDownItemBorder": `DropDownMenu_DropDownItemBorder__uKUey`,
	"DropDownItemText": `DropDownMenu_DropDownItemText__UvHVz`,
	"IconStyle": `DropDownMenu_IconStyle__40Tc6`
};
export default ___CSS_LOADER_EXPORT___;
