// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support-item {
  margin-top: 1rem;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}

.support-item label {
  font-size: 1rem;
  font-weight: bold;
}

.support-item p {
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.025);
  background-color: rgba(0, 0, 0, 0.025);
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AdminSupportSingle/AdminSupportSingleModule.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,+CAA+C;AACjD;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,sCAAsC;EACtC,sCAAsC;EACtC,kBAAkB;AACpB","sourcesContent":[".support-item {\n  margin-top: 1rem;\n  background-color: #fff;\n  border-radius: 10px;\n  padding: 20px;\n  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);\n}\n\n.support-item label {\n  font-size: 1rem;\n  font-weight: bold;\n}\n\n.support-item p {\n  font-size: 1rem;\n  margin-bottom: 1rem;\n  margin-top: 0.5rem;\n  padding: 0.5rem;\n  border: 1px solid rgba(0, 0, 0, 0.025);\n  background-color: rgba(0, 0, 0, 0.025);\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
