// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlap-group {
  background-color: #f4f5f9;
  border: 0.41px solid;
  border-color: #6a6e8333;
  border-radius: 36.57px;
  height: 46px;
  width: 80px;
  float: right;
  padding: 5px;
}

.ellipse {
  height: 33px;
  object-fit: cover;
  width: 33px;
}

.text-wrapper-2 {
  color: var(--heading-color);
  font-size: 14px;
  font-weight: 600;
  height: 19px;
  letter-spacing: 0;
  line-height: normal;
  padding: 5px;
}

.arrow-chevron-down {
  height: 20px !important;
  width: 20px !important;
}

.text-3 {
  color: #53605f;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
}

.image {
  height: 33px;
  width: 33px;
}
.group:hover {
  text-decoration: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/UserBadge/UserBadge.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,oBAAoB;EACpB,uBAAuB;EACvB,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,gCAAgC;AAClC","sourcesContent":[".overlap-group {\n  background-color: #f4f5f9;\n  border: 0.41px solid;\n  border-color: #6a6e8333;\n  border-radius: 36.57px;\n  height: 46px;\n  width: 80px;\n  float: right;\n  padding: 5px;\n}\n\n.ellipse {\n  height: 33px;\n  object-fit: cover;\n  width: 33px;\n}\n\n.text-wrapper-2 {\n  color: var(--heading-color);\n  font-size: 14px;\n  font-weight: 600;\n  height: 19px;\n  letter-spacing: 0;\n  line-height: normal;\n  padding: 5px;\n}\n\n.arrow-chevron-down {\n  height: 20px !important;\n  width: 20px !important;\n}\n\n.text-3 {\n  color: #53605f;\n  font-size: 20px;\n  font-weight: 700;\n  letter-spacing: 0;\n  line-height: 28px;\n}\n\n.image {\n  height: 33px;\n  width: 33px;\n}\n.group:hover {\n  text-decoration: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
